import React from 'react';
import ReactDOM from 'react-dom/client';
import { App, AuthModalProvider, ScrollToTop } from './components';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { store } from './store';
import { theme } from './styles/constants';
import './styles/fonts.css';
import { injectStore } from './api';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SubscriptionModalProvider } from './components/modals/SubscriptionModal/SubscriptionModal';
import { TelegramModalProvider } from './components/modals/TelegramModal/TelegramModal';
import { HelmetProvider } from 'react-helmet-async';

injectStore(store);

const CLIENT_ID = '1026748736762-1bdqq356chttoct994l79s2eioa1ehhq.apps.googleusercontent.com';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <GoogleOAuthProvider clientId={CLIENT_ID}>
            <HelmetProvider>
              <TelegramModalProvider>
                <AuthModalProvider>
                  <SubscriptionModalProvider>
                    <ScrollToTop />
                    <App />
                  </SubscriptionModalProvider>
                </AuthModalProvider>
              </TelegramModalProvider>
            </HelmetProvider>
          </GoogleOAuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
